:root {
  --Crimson: 'Crimson', serif;
  --CrimsonSemiBold: 'Crimson SemiBold', serif;
  --Vollkorn: 'Vollkorn', serif;
  --VollkornSC: 'Vollkorn SC', serif;
  --Asap: 'Asap', sans-serif;
  --AsapBold: 'AsapBold', sans-serif;
  --AsapItalic: 'AsapItalic', sans-serif;
}

@font-face {
  font-family: 'Vollkorn SC';
  src:
    url('../assets/fonts/VollkornCS/VollkornSC-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Vollkorn';
  src:
    url('../assets/fonts/Vollkorn/Vollkorn-Regular.woff2') format('woff2'),
    url('../assets/fonts/Vollkorn/Vollkorn-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Crimson';
  src:
    url('../assets/fonts/Crimson_Text/Crimson-Regular.woff2') format('woff2'),
    url('../assets/fonts/Crimson_Text/Crimson-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Crimson SemiBold';
  src:
    url('../assets/fonts/Crimson_Text/Crimson-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Crimson_Text/Crimson-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Asap';
  src:
    url('../assets/fonts/Asap/Asap-Regular.woff2') format('woff2'),
    url('../assets/fonts/Asap/Asap-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AsapBold';
  src:
    url('../assets/fonts/Asap/Asap-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Asap/Asap-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AsapItalic';
  src:
    url('../assets/fonts/Asap/Asap-Italic.woff2') format('woff2'),
    url('../assets/fonts/Asap/Asap-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
