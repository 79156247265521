/* To set a theme locally, run the following command in your terminal: philespace.flags.feature.theme('<tenant>') */

:root {
  /* Base colors */
  --white: rgb(255, 255, 255);
  --black: rgb(0, 0, 0);
  --grey-dark: rgb(94, 94, 94);
  --grey: rgb(167, 167, 167);
  --grey-raw: 167, 167, 167;
  --grey-light: rgb(219, 219, 219);
  --grey-light-raw: 219, 219, 219;
  --grey-lightest: rgb(244, 244, 244);

  --red-heart: rgb(249, 80, 88);
  --orange-red: rgb(222, 58, 5);
  --orange-red-raw: 222, 58, 5;

  --yellow: rgb(245, 231, 21);
  --gold: rgb(226, 168, 86);
  --brown: rgb(194, 132, 63);

  --green: rgb(56, 192, 38);

  --blue-grey: rgb(92, 98, 118);
  --blue-grey-raw: 92, 98, 118;
  --blue-grey-light: rgb(163, 173, 180);
  --blue-grey-lighter: rgb(214, 219, 220);

  --blue-darker: rgb(21, 43, 56);
  --blue-dark: rgb(3, 51, 73);
  --blue: rgb(29, 83, 110);
  --blue-light: rgb(76, 98, 126);
  --blue-mui: rgb(33, 150, 243);

  --purple: rgb(138, 43, 226);

  --cs-blue: rgb(26, 20, 73);
  --cs-orange: rgb(241, 92, 38);
  --cs-grey: rgb(180, 180, 180);

  --timeline-highlight-raw: var(--orange-red-raw);

  /* ---------------- */

  --border-regular: 0.5px solid rgba(var(--grey-light-raw), 0.3);
  --border-regular-vignette-button: 2px solid rgba(var(--grey-light-raw), 0.3);

  /* Shadows */
  --shadow-small: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  --shadow-medium: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  --shadow-large: 0 8px 16px rgba(0, 0, 0, 0.19), 0 4px 4px rgba(0, 0, 0, 0.23);

  --shadow-popup: var(--shadow-large);
  --shadow-floating: var(--shadow-large);

  --shadow-widget-left: 0 0 12px rgba(0, 0, 0, 0.4);
  --shadow-widget-right: 0 0 12px rgba(0, 0, 0, 0.4);
  --shadow-widget-bottom: 0 -3px 6px -3px rgba(0, 0, 0, 0.4);
}
