
body {
  margin: 0;
}

.error-page {
  position: absolute;
  background: var(--grey-lightest);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.error-page .centered {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 500px;
}

.error-page h1 {
  font: 22px var(--AsapBold);
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--text-light);
  display: inline-block;
  width: 100%;
  text-align: center;
}

.error-page .description {
  font: 20px var(--Crimson);
  color: var(--grey-dark);
  margin: 0;

  p {
    margin-top: 5px;
  }

  .user-email {
    font-family: var(--Asap);
    color: var(--brown);
  }
}
